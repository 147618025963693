<template>
  <div class="center">
    <div ref="info" class="info">
      <div class="text">
        <div class="online">
          <div class="title">
            <div class="icon">
              <img class="icon1" :src="icon1">
            </div>
            <div class="text-online">
              线上支持
              <div class="min-divider" />
            </div>
          </div>
          <div class="email">邮箱：marketing@linkstor.com</div>
          <div class="phone">电话：020-39932660</div>
        </div>
        <div class="max-divider" />
        <div class="offline">
          <div class="title">
            <div class="icon">
              <img class="icon2" :src="icon2">
            </div>
            <div class="text-offline">
              线下支持
              <div class="min-divider" />
            </div>
          </div>
          <div class="address">
            地&nbsp;&nbsp;&nbsp;&nbsp;址：广州市番禺区南村镇汉溪大道东362号自编3002-1单元
          </div>
          <div class="time">
            办公时间：工作日9:00-18:00
          </div>
        </div>
      </div>
      <div class="img">
        <img class="contract-img" :src="contract">
      </div>
    </div>
    <div class="map">
<!--      <img :src="map">-->
      <baidu-map class="bm-view" ak="VOUG5tpMCVyjSKyeb314DYwd9OPOMG5f" :center="{lng: 113.35416, lat: 23.00938}" :zoom="18">
        <bm-scale class="bm-scale" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <bm-navigation class="bm-nav" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
<!--        <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>-->
        <bm-marker :position="{lng: 113.35416, lat: 23.00938}" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
          <bm-label content="广州数源畅联科技有限公司" :labelStyle="{fontSize : '15px'}" :offset="{width: -35, height: 30}"/>
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>
<script>

export default {
  name: "index",
  data() {
    return {
      contract: require('../../assets/customer/contract.png'),
      // map: require('../../assets/customer/map.png'),
      icon1: require('../../assets/customer/icon1.png'),
      icon2: require('../../assets/customer/icon2.png'),
    }
  },
  mounted() {
    if(this.$route.params){
      if(this.$route.params.id == 'online' || this.$route.params.id == 'offline') {
        this.$refs.info.scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.center {
  width: 1920px;
  height: 1462px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .info {
    width: 1401px;
    height: 623px;
    display: flex;
    justify-content: center;
    .img {
     padding-left: 120px;
      .contract-img {
        width: 643px;
        height: 617px;
      }
    }
    .text {
      margin-left: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .online {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .title {
          display: flex;
          justify-content: center;
          .icon1 {
            width: 85px;
            height: 85px;
          }
          .text-online {
            margin-top: 12px;
            margin-left: 20px;
            opacity: 1;
            /** 文本1 */
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 46.34px;
            color: rgba(51, 51, 51, 1);
            text-align: left;
            vertical-align: top;
            .min-divider {
              margin-top: 6px;
            }
          }
        }
        .email {
          margin-left: 220px;
          /** 文本1 */
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0px;
          line-height: 26.06px;
          color: rgba(51, 51, 51, 1);
        }
        .phone {
          margin-left: 140px;
          margin-top: 12px;
          /** 文本1 */
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0px;
          line-height: 26.06px;
          color: rgba(51, 51, 51, 1);
        }
      }
      .max-divider {
        width: 516px;
        margin-left: 500px;
        margin-top: 100px;
      }
      .offline {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .title {
          display: flex;
          justify-content: center;
          .icon2 {
            width: 85px;
            height: 85px;
          }
          .text-offline {
            margin-top: 12px;
            margin-left: 20px;
            opacity: 1;
            /** 文本1 */
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 46.34px;
            color: rgba(51, 51, 51, 1);
            text-align: left;
            vertical-align: top;
            .min-divider {
              margin-top: 6px;
            }
          }
        }
        .address {
          margin-left: 240px;
          width: 100%;
          /** 文本1 */
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0px;
          line-height: 26.06px;
          color: rgba(51, 51, 51, 1);
        }
        .time {
          margin-left: 210px;
          margin-top: 12px;
          /** 文本1 */
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 0px;
          line-height: 26.06px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  .map {
    margin-top: 80px;
    width: 1400px;
    height: 583px;
    margin-left: 260px;
    .bm-view {
      width: 1400px;
      height: 583px;
      .bm-scale {
        width: 57px;
        height: 442px;
      }
      .bm-nav {
        width: 20px;
        height: 100px;
      }
    }
  }
}
.min-divider {
  width: 40px;
  height: 6px;
  opacity: 1;
  border-radius: 3px;
  background: rgba(13, 126, 191, 1);
}
.max-divider {
  width: 516px;
  height: 1px;
  opacity: 1;
  background: rgba(112, 168, 64, 1);

}
</style>