<template>
	<div class="eManage_challenge">
		<p class="eManage_challenge_p">痛点/挑战</p>
		<div class="eManage_challenge_wrap">
			<div class="eManage_challenge_content">
				<div class="eManage_challenge_content_div1">
					<div class="eManage_challenge_div1_wrap">
						<img class="eManage_challenge_div1_img" :src="fengxian" />
						<div class="eManage_challenge_div1_right">
							<p class="eManage_challenge_div1_right_p1">信息安全风险高</p>
							<p class="eManage_challenge_div1_right_p2">数据本地存储，易泄露，外设和应用难以管控</p>
						</div>
					</div>
				</div>
				<div class="eManage_challenge_content_div2">
					<div class="eManage_challenge_div2_wrap">
						<img class="eManage_challenge_div2_img" :src="xiaolv" />
						<div class="eManage_challenge_div2_right">
							<p class="eManage_challenge_div2_right_p1">办公效率低</p>
							<p class="eManage_challenge_div2_right_p2">桌面上下线慢，故障率高，IT运维复杂</p>
						</div>
					</div>
				</div>
				<div class="eManage_challenge_content_div3">
					<div class="eManage_challenge_div3_wrap">
						<img class="eManage_challenge_div3_img" :src="yunying" />
						<div class="eManage_challenge_div3_right">
							<p class="eManage_challenge_div3_right_p1">运营成本高</p>
							<p class="eManage_challenge_div3_right_p2">电力、人力成本高，替换周期频繁</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EManageChallenge",
	data() {
		return {
			fengxian: require('@/assets/eManage/eManage_challenge/3.png'),
			xiaolv: require('@/assets/eManage/eManage_challenge/2.png'),
			yunying: require('@/assets/eManage/eManage_challenge/1.png'),
		}
	},
	methods: {},
	created() {},
	computed: {},
	watch: {},
}
</script>

<style lang="scss" scoped>
.eManage_challenge{
	width: 1920px;
	height: 500px;
	.eManage_challenge_p{
		padding-top: 9%;
		width: 1920px;
		height: 47px;
		opacity: 1;
		/** 文本1 */
		font-size: 31.92px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 47px;
		color: rgba(34, 34, 34, 1);
		text-align: center;
		vertical-align: top;
	}
	.eManage_challenge_wrap{
		margin-top: 35px;
		width: 1920px;
		height: 199px;
		display: flex;
		justify-content: center;
		.eManage_challenge_content{
			width: 1397px;
			height: 199px;
			.eManage_challenge_content_div1{
				float: left;
				margin-right: 40px;
				width: 439px;
				height: 199px;
				background: rgba(245, 248, 255, 1);
				.eManage_challenge_div1_wrap{
					margin-top: 53px;
					margin-left: 41px;
					width: 345px;
					height: 139px;
					.eManage_challenge_div1_img{
						float: left;
						margin-top: 8px;
						width: 62px;
						height: 70px;
					}
					.eManage_challenge_div1_right{
						float: left;
						margin-left: 27px;
						width: 256px;
						height: 139px;
						.eManage_challenge_div1_right_p1{
							width: 100%;
							height: 35px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(51, 51, 51, 1);
							text-align: left;
							vertical-align: top;
						}
						.eManage_challenge_div1_right_p2{
							margin-top: 10px;
							width: 255.83px;
							height: 94.08px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(102, 102, 102, 1);
							text-align: left;
							vertical-align: top;
						}
					}
				}
			}
			.eManage_challenge_content_div2{
				float: left;
				margin-right: 40px;
				width: 439px;
				height: 199px;
				background: rgba(245, 248, 255, 1);
				.eManage_challenge_div2_wrap{
					margin-top: 53px;
					margin-left: 35px;
					width: 362px;
					height: 131px;
					.eManage_challenge_div2_img{
						float: left;
						margin-top: 1px;
						width: 56px;
						height: 70px;
					}
					.eManage_challenge_div2_right{
						float: left;
						margin-left: 28px;
						width: 278px;
						height: 131px;
						.eManage_challenge_div2_right_p1{
							width: 100%;
							height: 35px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(51, 51, 51, 1);
							text-align: left;
							vertical-align: top;
						}
						.eManage_challenge_div2_right_p2{
							margin-top: 10px;
							width: 277.78px;
							height: 86.11px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(102, 102, 102, 1);
							text-align: left;
							vertical-align: top;
						}
					}
				}
			}
			.eManage_challenge_content_div3{
				float: left;
				width: 439px;
				height: 199px;
				background: rgba(245, 248, 255, 1);
				.eManage_challenge_div3_wrap{
					margin-top: 53px;
					margin-left: 41px;
					width: 345px;
					height: 133px;
					.eManage_challenge_div3_img{
						float: left;
						margin-top: 3px;
						width: 55px;
						height: 64px;
					}
					.eManage_challenge_div3_right{
						float: left;
						margin-left: 34px;
						width: 256px;
						height: 133px;
						.eManage_challenge_div3_right_p1{
							width: 100%;
							height: 34.91px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(51, 51, 51, 1);
							text-align: left;
							vertical-align: top;
						}
						.eManage_challenge_div3_right_p2{
							margin-top: 10px;
							width: 255.83px;
							height: 88.1px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(102, 102, 102, 1);
							text-align: left;
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}

</style>