<template>
	<div class="eMove_price" id="eMove_price">
		<div class="eMove_price_background">
			<img class="eMove_price_background_img" :src="priceBackground"/>
		</div>
		<div class="eMove_price_title_wrap">
			<p class="eMove_price_p1">价格/规格</p>
			<p class="eMove_price_p2">支持：windows 7，windows 8 ，windows 10、windows 11、 windows server 2016、windows server 2019、windows server 2022</p>
			<p class="eMove_price_p3">windows系统与云电脑之间相互迁移的常用软件微信、钉钉、企业微信、飞书等超50款应用</p>
			<div class="eMove_price_content_wrap">
				<div class="eMove_price_content">
					<div class="eMove_price_content_meal">
						<div class="eMove_price_content_meal1">
							<div class="eMove_price_content_meal1_background">
								<img class="eMove_price_content_meal1_background_img" :src="meal1Background"/>
							</div>
							<div class="eMove_price_meal1_content">
								<p class="eMove_price_meal1_content_p1">套餐一</p>
								<p class="eMove_price_meal1_content_p2">数联e键搬家工具</p>
								<div class="eMove_price_meal1_content_wrap">
									<div class="eMove_price_meal1_content_price">
										<img class="eMove_price_meal1_content_price_img" :src="meal2"/>
										<p class="eMove_price_meal1_content_price_p">月包69元</p>
									</div>
								</div>
							</div>
						</div>
						<div class="eMove_price_content_meal2">
							<div class="eMove_price_content_meal2_background">
								<img class="eMove_price_content_meal2_background_img" :src="meal2Background"/>
							</div>
							<div class="eMove_price_meal2_content">
								<p class="eMove_price_meal2_content_p1">套餐二</p>
								<p class="eMove_price_meal2_content_p2">数联e键搬家工具</p>
								<div class="eMove_price_meal2_content_wrap">
									<div class="eMove_price_meal2_content_price">
										<img class="eMove_price_meal2_content_price_img" :src="meal1"/>
										<p class="eMove_price_meal2_content_price_p">年包189元</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p class="eMove_price_content_p">
						了解更多，请留下联系方式，我们会第一时间联系您
					</p>
					<el-form ref="form" :model="form" :rules="rules" class="eMove_price_content_form">
						<el-form-item class="eMove_price_content_form_item1" prop="name">
							<el-input v-model="form.name" placeholder="姓名 *" class="eMove_price_content_form_item_input"></el-input>
						</el-form-item>
						<el-form-item class="eMove_price_content_form_item2" prop="phone">
							<el-input v-model="form.phone" placeholder="电话 *" class="eMove_price_content_form_item"></el-input>
						</el-form-item>
						<el-form-item class="eMove_price_content_form_item3" prop="email">
							<el-input v-model="form.email" placeholder="邮箱" class="eMove_price_content_form_item"></el-input>
						</el-form-item>
						<el-form-item class="eMove_price_content_form_item4" prop="address">
							<el-input v-model="form.address" placeholder="地址" class="eMove_price_content_form_item"></el-input>
						</el-form-item>
						<el-form-item class="eMove_price_content_form_item5" prop="text">
							<el-input v-model="form.text" placeholder="内容 *" class="eMove_price_content_form_text" type="textarea"></el-input>
						</el-form-item>
						<el-form-item class="eMove_price_content_form_item6">
							<el-button type="primary" @click="onSubmit" :loading="submitLoading">提交</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	name: "EMovePrice",
	data() {
		var checkPhone = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入您的手机号码'));
			}
			let isPhone = /^1[3-9]\d{9}$/.test(value)
			if (isPhone){
				callback();
			}else{
				callback(new Error('请输入正确的手机号码'));
			}
		};
		/*var checkEmail = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入您的邮箱地址'));
			}
			let isEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)
			if (isEmail){
				callback();
			}else{
				callback(new Error('请输入正确的邮箱地址'));
			}
		};*/
		return {
			priceBackground: require('@/assets/eMove/eMove-price/eMove_price_background.png'),
			meal1Background: require('@/assets/eMove/eMove-price/2.png'),
			meal2: require('@/assets/eMove/eMove-price/meal2.png'),
			meal2Background: require('@/assets/eMove/eMove-price/1.png'),
			meal1: require('@/assets/eMove/eMove-price/meal1.png'),
			form: {
				name: '',
				phone: '',
				email: '',
				address: '',
				text: '',
			},
			rules: {
				name: [
					{ required: true, message: '请输入您的姓名', trigger: 'blur' },
				],
				phone: [
					{ required: true, validator: checkPhone, trigger: 'blur' },
				],
				text: [
					{ required: true, message: '请输入您要咨询的内容', trigger: 'blur' },
				],
			},
			submitLoading: false,
		}
	},
	methods: {
		onSubmit() {
			var that = this
			this.$refs['form'].validate((valid)=>{
				if (valid){
					this.submitLoading = true;
					axios({
						method: 'post',
						url: 'https://formspree.io/f/xwkdjjkk',
            // url: '/formspree',
            data: this.form,

					}).then(response => {
						console.log(response);
						that.$notify({
							title: '成功',
							message: '邮件已发送',
							type: 'success'
						});
					}, error => {
						that.$notify({
							title: '失败',
							message: '邮件发送失败',
							type: 'error'
						})
						console.log('错误', error.message)
					}).finally(()=>{
						this.$refs['form'].resetFields();
						this.submitLoading = false
					});
				}else{
					return false;
				}
			})
		}
	},
	created() {

	},
	computed: {},
	watch: {},

}
</script>

<style lang="scss" scoped>
.eMove_price{
	position: relative;
	width: 1920px;
	height: 1204px;
	.eMove_price_background{
		position: absolute;
		width: 100%;
		height: 1204px;
		.eMove_price_background_img{
			width: 100%;
			height: 1204px;
		}
	}
	.eMove_price_title_wrap{
		position: absolute;
		width: 100%;
		height: 1124px;
		.eMove_price_p1{
			margin-top: 71px;
			width: 100%;
			height: 47px;
			opacity: 1;
			/** 文本1 */
			font-size: 31.92px;
			font-weight: 700;
			letter-spacing: 0;
			line-height: 46.22px;
			color: rgba(34, 34, 34, 1);
			text-align: center;
			vertical-align: top;
		}
		.eMove_price_p2{
			margin-top: 31px;
			width: 100%;
			height: 32px;
			opacity: 1;
			/** 文本1 */
			font-size: 21.94px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 31.78px;
			color: rgba(51, 51, 51, 1);
			text-align: center;
			vertical-align: top;
		}
		.eMove_price_p3{
			margin-top: 3px;
			width: 100%;
			height: 32px;
			opacity: 1;
			/** 文本1 */
			font-size: 21.94px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 31.78px;
			color: rgba(51, 51, 51, 1);
			text-align: center;
			vertical-align: top;
		}
		.eMove_price_content_wrap{
			margin-top: 66px;
			display: flex;
			justify-content: center;
			width: 100%;
			height: 840px;
			.eMove_price_content{
				width: 918px;
				height: 840px;
				.eMove_price_content_meal{
					width: 918px;
					height: 377px;
					.eMove_price_content_meal1{
						float: left;
						margin-right: 40px;
						position: relative;
						width: 439px;
						height: 377px;
						.eMove_price_content_meal1_background{
							position: absolute;
							width: 439px;
							height: 377px;
							.eMove_price_content_meal1_background_img{
								width: 439px;
								height: 377px;
							}
						}
						.eMove_price_meal1_content{
							position: absolute;
							width: 439px;
							height: 377px;
							.eMove_price_meal1_content_p1{
								width: 100%;
								height: 98px;
								opacity: 1;
								/** 文本1 */
								font-size: 31.92px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 98px;
								color: rgba(255, 255, 255, 1);
								text-align: center;
								vertical-align: top;
							}
							.eMove_price_meal1_content_p2{
								margin-top: 47px;
								width: 100%;
								height: 32px;
								opacity: 1;
								/** 文本1 */
								font-size: 21.94px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 31.78px;
								color: rgba(51, 51, 51, 1);
								text-align: center;
								vertical-align: top;
							}
							.eMove_price_meal1_content_wrap{
								margin-top: 31px;
								display: flex;
								justify-content: center;
								width: 100%;
								height: 32px;
								.eMove_price_meal1_content_price{
									width: 132px;
									height: 32px;
									.eMove_price_meal1_content_price_img{
										float: left;
										width: 29px;
										height: 31px;
									}
									.eMove_price_meal1_content_price_p{
										float: left;
										margin-left: 9px;
										width: 94px;
										height: 32px;
										opacity: 1;
										/** 文本1 */
										font-size: 21.94px;
										font-weight: 700;
										letter-spacing: 0;
										line-height: 32px;
										color: rgba(13, 126, 191, 1);
										text-align: center;
										vertical-align: top;
									}
								}
							}
						}
					}
					.eMove_price_content_meal2{
						float: left;
						position: relative;
						width: 439px;
						height: 377px;
						.eMove_price_content_meal2_background{
							position: absolute;
							width: 439px;
							height: 377px;
							.eMove_price_content_meal2_background_img{
								width: 439px;
								height: 377px;
							}
						}
						.eMove_price_meal2_content{
							position: absolute;
							width: 439px;
							height: 377px;
							.eMove_price_meal2_content_p1{
								width: 100%;
								height: 98px;
								opacity: 1;
								/** 文本1 */
								font-size: 31.92px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 98px;
								color: rgba(255, 255, 255, 1);
								text-align: center;
								vertical-align: top;
							}
							.eMove_price_meal2_content_p2{
								margin-top: 47px;
								width: 100%;
								height: 32px;
								opacity: 1;
								/** 文本1 */
								font-size: 21.94px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 31.78px;
								color: rgba(51, 51, 51, 1);
								text-align: center;
								vertical-align: top;
							}
							.eMove_price_meal2_content_wrap{
								margin-top: 31px;
								display: flex;
								justify-content: center;
								width: 100%;
								height: 32px;
								.eMove_price_meal2_content_price{
									width: 142px;
									height: 32px;
									.eMove_price_meal2_content_price_img{
										float: left;
										width: 29px;
										height: 31px;
									}
									.eMove_price_meal2_content_price_p{
										float: left;
										margin-left: 6px;
										width: 107px;
										height: 32px;
										opacity: 1;
										/** 文本1 */
										font-size: 21.94px;
										font-weight: 700;
										letter-spacing: 0;
										line-height: 32px;
										color: rgba(112, 168, 64, 1);
										text-align: center;
										vertical-align: top;
									}
								}
							}
						}
					}
				}
				.eMove_price_content_p{
					margin-top: 75px;
					width: 100%;
					height: 32px;
					opacity: 1;
					/** 文本1 */
					font-size: 21.94px;
					font-weight: 300;
					letter-spacing: 0;
					line-height: 32px;
					color: rgba(51, 51, 51, 1);
					text-align: center;
					vertical-align: top;
				}
				.eMove_price_content_form{
					margin-top: 36px;
					width: 100%;
					height: 320px;
					.eMove_price_content_form_item1{
						float: left;
						margin-right: 40px;
						margin-bottom: 20px;
						width: 439px;
						height: 52px;
						.eMove_price_content_form_item_input{
              width: 439px;
							height: 52px;
						}
					}
					.eMove_price_content_form_item2{
						float: left;
						margin-bottom: 20px;
						width: 439px;
						height: 52px;
            .eMove_price_content_form_item {
              width: 439px;
              height: 52px;
            }
					}
					.eMove_price_content_form_item3{
						float: left;
						margin-right: 40px;
						margin-bottom: 20px;
						width: 439px;
						height: 52px;
            .eMove_price_content_form_item {
              width: 439px;
              height: 52px;
            }
					}
					.eMove_price_content_form_item4{
						float: left;
						margin-bottom: 20px;
						width: 439px;
						height: 52px;
            .eMove_price_content_form_item {
              width: 439px;
              height: 52px;
            }
					}
					.eMove_price_content_form_item5{
						float: left;
						width: 100%;
						height: 104px;
						::v-deep .el-textarea__inner{
							color: black;
							font-size: 16px;
              height: 100%;
              min-height: 100%;
						}
            .eMove_price_content_form_text {
              width: 100%;
              height: 104px;
            }
					}
					.eMove_price_content_form_item6{
            width: 227px;
            margin-left: 35%;
            height: 52px;
						float: left;
						::v-deep .el-button{
							width: 227px;
							height: 52px;
						}
					}
				}
			}
		}

	}
}
::v-deep .el-input__inner{
	height: 52px;
}
</style>