<template>
	<div class="eMigrate_application">
		<p class="eMove_application_p">应用场景</p>
		<div class="eMove_application_wrap">
			<div class="eMove_application_wrap_div">
				<div v-on:mouseenter="tabOnmouseover('div1')" class="eMove_application_div1" :class="{active: active == 'div1'}">
					<div class="eMove_application_div1_backtground">
						<img class="eMove_application_div1_backtground_img" :src="first"/>
					</div>
					<div class="eMove_application_div1_wrap">
						<p class="eMove_application_div1_wrap_p1">云迁移</p>
            <div class="divider"></div>
						<p v-if="active == 'div1'" class="eMove_application_div1_wrap_p2">将数据从本地数据中心迁移到云平台，以获得更高的可扩展性、灵活性和成本效益</p>
            <p v-else class="eMove_application_div1_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
				</div>
				<div v-on:mouseenter="tabOnmouseover('div2')" class="eMove_application_div2" :class="{active: active == 'div2'}">
					<div class="eMove_application_div2_backtground">
						<img class="eMove_application_div2_backtground_img" :src="second"/>
					</div>
					<div class="eMove_application_div2_wrap">
						<p class="eMove_application_div2_wrap_p1">数据中心迁移</p>
            <div class="divider"></div>
            <p v-if="active == 'div2'" class="eMove_application_div2_wrap_p2">企业更换或合并数据中心时，数据迁移是必不可少的。涉及将数据从一个数据中心转移到另一个数据中心，以确保业务的连续性和数据的完整性。</p>
            <p v-else class="eMove_application_div2_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
				</div>
				<div v-on:mouseenter="tabOnmouseover('div3')" class="eMove_application_div3" :class="{active: active == 'div3'}">
					<div class="eMove_application_div3_backtground">
						<img class="eMove_application_div3_backtground_img" :src="third"/>
					</div>
					<div class="eMove_application_div3_wrap">
						<p class="eMove_application_div3_wrap_p1">数据库迁移</p>
            <div class="divider"></div>
            <p v-if="active == 'div3'" class="eMove_application_div3_wrap_p2">企业更换数据库系统、升级数据库版本或将数据从一个数据库平台迁移到另一个数据库平台时。这可以是在企业内部进行的，也可以是在云平台上进行的。</p>
            <p v-else class="eMove_application_div3_wrap_p2">鼠标聚焦，查看介绍</p>
					</div>
				</div>
				<div v-on:mouseenter="tabOnmouseover('div4')" class="eMove_application_div4" :class="{active: active == 'div4'}">
					<div class="eMove_application_div4_backtground">
						<img class="eMove_application_div4_backtground_img" :src="fourth"/>
					</div>
					<div class="eMove_application_div4_wrap">
						<p class="eMove_application_div4_wrap_p1">应用程序迁移</p>
            <div class="divider"></div>
            <p v-if="active == 'div4'" class="eMove_application_div4_wrap_p2">企业决定更换或升级应用程序时，它涉及将应用程序所使用的数据从旧应用程序迁移到新应用程序，以确保数据的连续性和一致性。</p>
            <p v-else class="eMove_application_div4_wrap_p2">鼠标聚焦，查看介绍</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMigrateApplication",
	data(){
		return {
			first: require('@/assets/eMigrate/eMigrate-application/4.png'),
			second: require('@/assets/eMigrate/eMigrate-application/3.png'),
			third: require('@/assets/eMigrate/eMigrate-application/2.png'),
			fourth: require('@/assets/eMigrate/eMigrate-application/1.png'),
      active: 'div1',
		}
	},
	methods:{
    tabOnmouseover(name) {
      this.active = name
    }
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style lang="scss" scoped>
.active {
  transition: width 1.3s;
  width: 556px !important;
}
.divider {
  margin-left: 41px;
  margin-top: 12.25px;
  width: 46px;
  height: 6px;
  opacity: 1;
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);

}
.eMigrate_application{
	padding-top: 71px;
	width: 1920px;
	height: 662px;
	background: rgba(245, 248, 255, 1);
	.eMove_application_p{
		width: 100%;
		height: 47px;
		opacity: 1;
		/** 文本1 */
		font-size: 31.92px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 46.22px;
		color: rgba(34, 34, 34, 1);
		text-align: center;
		vertical-align: top;
	}
	.eMove_application_wrap{
		width: 1920px;
		height: 501px;
		margin-top: 33px;
		/* 子元素居中 */
		display: flex;
		justify-content: center; /* 子元素水平居中 */
		.eMove_application_wrap_div{
      margin-left: 250px;
			width: 1920px;
			height: 501px;
			.eMove_application_div1{
				float: left;
				margin-right: 1px;
				position: relative;
				width: 280px;
				height: 501px;
				.eMove_application_div1_backtground{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div1_backtground_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_application_div1_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div1_wrap_p1{
						margin-top: 368px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_application_div1_wrap_p2{
						margin-left: 40px;
						margin-top: 12px;
						width: 390.98px;
						height: 42.89px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
				}
			}
			.eMove_application_div2{
				float: left;
				margin-right: 1px;
				position: relative;
				width: 280px;
				height: 501px;
				.eMove_application_div2_backtground{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div2_backtground_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_application_div2_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div2_wrap_p1{
						margin-top: 368px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0px;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
          .eMove_application_div2_wrap_p2{
            margin-left: 40px;
            margin-top: 12px;
            width: 390.98px;
            height: 42.89px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
				}
			}
			.eMove_application_div3{
				float: left;
				margin-right: 1px;
				position: relative;
				width: 280px;
				height: 501px;
				.eMove_application_div3_backtground{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div3_backtground_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_application_div3_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div3_wrap_p1{
						margin-top: 368px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
          .eMove_application_div3_wrap_p2{
            margin-left: 40px;
            margin-top: 12px;
            width: 390.98px;
            height: 42.89px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
				}
			}
			.eMove_application_div4{
				float: left;
				position: relative;
				width: 280px;
				height: 501px;
				.eMove_application_div4_backtground{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div4_backtground_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_application_div4_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_application_div4_wrap_p1{
						margin-top: 368px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
          .eMove_application_div4_wrap_p2{
            margin-left: 40px;
            margin-top: 12px;
            width: 390.98px;
            height: 42.89px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
				}
			}
		}
	}
}
</style>