<template>
	<div class="center">
		<EMoveIntroduction/>
		<EMoveFunction/>
		<EMoveValue/>
		<EMoveScenarios/>
		<EMovePrice/>
	</div>
</template>

<script>

import EMoveIntroduction from "@/components/eMove/eMove-introduction/index.vue";
import EMoveFunction from "@/components/eMove/eMove-function/index.vue";
import EMoveValue from "@/components/eMove/eMove-value/index.vue";
import EMoveScenarios from "@/components/eMove/eMove-scenarios/index.vue";
import EMovePrice from "@/components/eMove/eMove-price/index.vue";

export default {
	name: "index",
	components: {EMovePrice, EMoveScenarios, EMoveValue, EMoveFunction, EMoveIntroduction}
}
</script>

<style scoped>

</style>