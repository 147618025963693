<template>
	<div class="advantage">
		<p class="advantage_top">优势</p>
		<div class="advantage_wrap">
			<div class="advantage_wrap_botton1">
				<div class="advantage_wrap_botton1_left">
					<p class="advantage_wrap_botton1_leftt_p1">安全可靠</p>
					<p class="advantage_wrap_botton1_leftt_p2">
						运行客户端的业务系统，将会对业务系统进行资源状态检测，并将检测数据上报到控制系统，用户可在控制系统上查询业务系统的性能状态</p>
				</div>
				<div class="advantage_wrap_botton1_right">
					<img class="safe-img" :src="safeBackground"/>
				</div>
			</div>
			<div class="advantage_wrap_botton2">
				<div class="advantage_wrap_botton2_left">
					<p class="advantage_wrap_botton2_left_p1">灵活管理</p>
					<p class="advantage_wrap_botton2_left_p2">
						以企业为单位创建账户，可设置租户管理员和下属用户；管理员根据用户、部门两个维度进行容量分配和权限控制，符合企业的个性化定制需求的同时，也保证每个账号对任务操作权限的安全</p>
				</div>
				<div class="advantage_wrap_botton2_right">
					<img class="icon-img" :src="tuoyuan"/>
				</div>
			</div>
			<div class="advantage_wrap_botton3">
				<div class="advantage_wrap_botton3_left">
					<p class="advantage_wrap_botton3_left_p1">可视化管理</p>
					<p class="advantage_wrap_botton3_left_p2">
						通过直观的图形界面完成各种设置和操作，提供了一站式的管理平台，用户可以在同一个界面上完成多个任务，如数据备份、恢复、监控等，提供状态实时监控；支持日志管理和告警信息；提供多种报警方式</p>
				</div>
				<div class="advantage_wrap_botton3_right">
					<img class="manage-img" :src="manageBackground"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Advantage",
	data() {
		return {
			safeBackground: require('@/assets/advantage/safe.png'),
			manageBackground: require('@/assets/advantage/manage.png'),
			tuoyuan: require('@/assets/advantage/tuoyuan.png')
		}
	},
	methods: {},
	created() {

	},
	computed: {},
	watch: {},

}
</script>

<style lang="scss" scoped>
.advantage {
	width: 1920px;
	height: 495px;
}

.advantage_top {
	padding-top: 70px;
	width: 100%;
	height: 47px;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(34, 34, 34, 1);
	text-align: center;
	vertical-align: top;
}

.advantage_wrap {
	/* 子元素水平居中 */
	display: flex;
	justify-content: center;
	padding-top: 44px;
}

.advantage_wrap_botton1 {
	width: 440px;
	height: 262px;
	margin-right: 40px;
	background: rgba(245, 248, 255, 1);
}

.advantage_wrap_botton2 {
	width: 440px;
	height: 262px;
	margin-right: 38px;
	background: linear-gradient(270deg, rgba(112, 168, 64, 1) 0%, rgba(13, 126, 191, 1) 100%);
}

.advantage_wrap_botton3 {
	width: 440px;
	height: 262px;
	background: rgba(245, 248, 255, 1);
}

.advantage_wrap_botton1_left {
	float: left;
	width: 250px;
	height: 202px;
	margin-left: 41px;
	margin-top: 34px;
}

.advantage_wrap_botton1_right {
	float: left;
	width: 64px;
	height: 76px;
	margin-top: 165px;
	margin-left: 56px;
  .safe-img {
    width: 64px;
    height: 76px;
  }
}

.advantage_wrap_botton1_leftt_p1 {
	width: 100%;
	height: 35px;
	/** 文本1 */
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 34.75px;
	color: rgba(51, 51, 51, 1);
	text-align: left;
	vertical-align: top;
}

.advantage_wrap_botton1_leftt_p2 {
	margin-top: 11px;
	width: 248px;
	height: 155.67px;
	/** 文本1 */
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 20.27px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}

.advantage_wrap_botton2_left {
	float: left;
	margin-top: 33px;
	margin-left: 41px;
	width: 248px;
	height: 178px;
}

.advantage_wrap_botton2_right {
	float: left;
	margin-top: 161px;
	margin-left: 47px;
	width: 79px;
	height: 82px;
  .icon-img {
    width: 79px;
    height: 82px;
  }
}

.advantage_wrap_botton2_left_p1 {
	width: 100%;
	height: 35px;
	/** 文本1 */
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 34.75px;
	color: rgba(255, 255, 255, 1);
	text-align: left;
	vertical-align: top;
}

.advantage_wrap_botton2_left_p2 {
	margin-top: 11px;
	width: 248px;
	height: 131.91px;
	/** 文本1 */
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 20.27px;
	color: rgba(255, 255, 255, 1);
	text-align: left;
	vertical-align: top;
}

.advantage_wrap_botton3_left {
	float: left;
	margin-top: 33px;
	margin-left: 42px;
	width: 248px;
	height: 203px;
}

.advantage_wrap_botton3_right {
	float: left;
	margin-left: 60px;
	margin-top: 172px;
	width: 65px;
	height: 65px;
  .manage-img {
    width: 65px;
    height: 65px;
  }
}

.advantage_wrap_botton3_left_p1 {
	width: 100%;
	height: 35px;
	/** 文本1 */
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 34.75px;
	color: rgba(51, 51, 51, 1);
	text-align: left;
	vertical-align: top;
}

.advantage_wrap_botton3_left_p2 {
	margin-top: 11px;
	width: 248px;
	height: 156.91px;
	/** 文本1 */
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 20.27px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
</style>