<template>
	<div class="deployment">
		<div class="wrap">
			<p class="wrap_p">部署方案</p>
			<div class="wrap_content">
				<div class="content_wrap">
					<div class="content_top">
						<div class="content_top_left">
							<div class="content_top_left_left">
								<img class="icon-img" :src="fuwu"/>
							</div>
							<div class="content_top_left_right">
								<p class="content_top_left_right_p1">
									私有化部署云融方案，实现跨云平台备份
								</p>
								<p class="content_top_left_right_p2">
									实现统一界面管理，实时备份，IO级颗粒度，完全消除备份时间窗口，多种备份等级自定义，全面的应用环境支持
								</p>
							</div>
						</div>
						<div class="content_top_right">
							<div class="content_top_left_left">
								<img class="icon-img" :src="beifen"/>
							</div>
							<div class="content_top_left_right">
								<p class="content_top_left_right_p1">
									先级的数据块级备份技术
								</p>
								<p class="content_top_left_right_p2">
									面向复杂环境的数据块级的高速备份技术，对备份难点的数据库及小文件的系统也能从容解决
								</p>
							</div>
						</div>
					</div>
					<div class="content_center"></div>
					<div class="content_botton">
						<img class="img-structure" :src="tuceng5"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Deployment",
	data(){
		return {
			fuwu: require('@/assets/deployment/fuwuyuzhichihuaban.png'),
			beifen: require('@/assets/deployment/beifenliebiao.png'),
			tuceng5: require('@/assets/deployment/tuceng5.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},
}
</script>

<style lang="scss" scoped>
.deployment{
	width: 1920px;
	height: 1030px;
  margin-top: 5%;
	background-color: rgba(245, 248, 255, 1);
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.wrap{
	margin-top: 71px;
	width: 1400px;
	height: 859px;
}
.wrap_p{
	width: 100%;
	height: 47px;
	opacity: 1;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(51, 51, 51, 1);
	text-align: center;
	vertical-align: top;
}
.wrap_content{
	margin-top: 33px;
	width: 1400px;
	height: 779px;
	background: rgba(255, 255, 255, 1);
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.content_wrap{
	margin-top: 102px;
	width: 1250px;
	height: 597px;
}
.content_top{
	width: 1250px;
	height: 132px;
}
.content_top_left{
	float: left;
	width: 575px;
	height: 132px;
	margin-right: 100px;
}
.content_top_right{
	float: left;
	width: 575px;
	height: 132px;
}
.content_top_left_left{
	float: left;
	width: 95px;
	height: 95px;
	border-radius: 50%;
	background: rgba(245, 248, 255, 1);
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
	align-items: center; /* 子元素垂直居中 */
  .icon-img {
    width: 95px;
    height: 95px;
  }
}
.content_top_left_right{
	float: left;
	margin-left: 30px;
	width: 450px;
	height: 132px;
}
.content_top_left_right_p1{
	margin-top: -5.21px;
	width: 324px;
	height: 27px;
	opacity: 1;
	/** 文本1 */
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 26.06px;
	color: rgba(51, 51, 51, 1);
	text-align: left;
	vertical-align: top;
}
.content_top_left_right_p2{
	margin-top: 11px;
	width: 449.73px;
	height: 99.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.content_center{
	margin-top: 13px;
	width: 1250px;
	height: 1px;
	opacity: 0.2;
	background: rgba(0, 0, 0, 1);
}
.content_botton{
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
  .img-structure {
    width: 1143px;
    height: 443px;
  }
}
.content_botton img{
	margin-top: 8px;
}
</style>