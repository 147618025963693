<template>
	<div class="eMove_challenge">
		<p class="eMove_challenge_p">痛点/挑战</p>
		<div class="eMove_challenge_div">
			<div class="div1">
				<div class="div1_wrap">
					<div class="div1_wrap_left">
						<img class="img1" :src="cost"/>
					</div>
					<div class="div1_wrap_right">
						<p class="div1_wrap_right_p">大规模数据迁移需要大量时间迁移效率低导致业务中断或延迟，停机迁移影响日常运行</p>
					</div>
				</div>
			</div>
			<div class="div2">
				<div class="div2_wrap">
					<div class="div2_wrap_left">
						<img class="img2" :src="devOps"/>
					</div>
					<div class="div2_wrap_right">
						<p class="div2_wrap_right_p">异构存储不同系统应用程序使用不同数据格式及结构，无法兼容造成数据孤岛</p>
					</div>
				</div>
			</div>
			<div class="div3">
				<div class="div3_wrap">
					<div class="div3_wrap_left">
						<img class="img3" :src="fault"/>
					</div>
					<div class="div3_wrap_right">
						<p class="div3_wrap_right_p">数据不一致，迁移过程造成损坏、丢失或者不完整，导致数据可用性及准确性受到影响</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMigrateChallenge",
	data(){
		return {
			cost: require('@/assets/eMigrate/eMigrate-challenge/3.png'),
			devOps: require('@/assets/eMigrate/eMigrate-challenge/2.png'),
			fault: require('@/assets/eMigrate/eMigrate-challenge/1.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style scoped>
.eMove_challenge{
	width: 1920px;
	height: 500px;
}
.eMove_challenge_p{
	width: 100%;
	height: 47px;
	padding-top: 9%;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	/*字符之间的间距设置为0像素，意味着字符之间没有额外的空间*/
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(34, 34, 34, 1);
	/*这将文本水平居中对齐*/
	text-align: center;
	/*文本在垂直方向上的对齐方式为顶部对齐*/
	vertical-align:top;
}
.eMove_challenge_div{
	width: 100%;
	height: 183px;
	padding-top: 56px;
	/* 子元素水平居中 */
	display: flex;
	justify-content: center;
}
.div1{
	float: left;
	margin-right: 40px;
	background-color: rgba(245, 248, 255, 1);
	width: 439px;
	height: 183px;
}
.div2{
	float: left;
	margin-right: 40px;
	background-color: rgba(245, 248, 255, 1);
	width: 439px;
	height: 183px;
}
.div3{
	float: left;
	background-color: rgba(245, 248, 255, 1);
	width: 439px;
	height: 183px;
}
.img1{
	width: 64px;
	height: 55px;
}
.img2{
	width: 59px;
	height: 59px;
}
.img3{
	width: 70px;
	height: 65px;
}
.div1_wrap{
	margin-top: 57px;
	margin-left: 32px;
	width: 353px;
	height: 131px;
}
.div2_wrap{
	margin-top: 57px;
	margin-left: 36px;
	width: 349px;
	height: 131px;
}
.div3_wrap{
	margin-top: 57px;
	margin-left: 30px;
	width: 355px;
	height: 131px;
}
.div1_wrap_left{
	float: left;
	margin-top: 6px;
}
.div1_wrap_right{
	float: left;
	width: 256px;
	height: 94px;
	padding-left: 33px;
}
.div1_wrap_right_p{
	width: 255.83px;
	height: 94.08px;
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.div2_wrap_left{
	float: left;
	margin-top: 6px;
}
.div2_wrap_right{
	float: left;
	margin-left: 34px;
	width: 256px;
	height: 86px;
}
.div2_wrap_right_p{
	width: 255.83px;
	height: 86.11px;
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.div3_wrap_left{
	float: left;
	margin-top: 3px;
}
.div3_wrap_right{
	float: left;
	margin-left: 29px;
	width: 256px;
	height: 88px;
}
.div3_wrap_right_p{
	width: 255.83px;
	height: 88.1px;
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
</style>