<template>
	<div class="center">
		<Challenge/>
		<Structure/>
		<Advantage/>
		<Application/>
		<Recommendation/>
	</div>
</template>

<script>
import Challenge from "@/components/eBackup/challenge/index.vue";
import Structure from "@/components/eBackup/structure/index.vue";
import Advantage from "@/components/eBackup/advantage/index.vue";
import Application from "@/components/eBackup/application/index.vue";
import Recommendation from "@/components/eBackup/recommendation/index.vue";


export default {
	name: "index",
	components: {Challenge, Structure, Advantage, Application, Recommendation},
}
</script>

<style scoped>

</style>
