<template>
	<div class="center">
		<EMigrateChallenge/>
	  	<EMigrateStructure/>
		<EMigrateAdvantage/>
		<EMigrateApplication/>
	</div>
</template>

<script>
import EMigrateChallenge from "@/components/eMigrate/eMigrate-challenge/index.vue";
import EMigrateStructure from "@/components/eMigrate/eMigrate-structure/index.vue";
import EMigrateAdvantage from "@/components/eMigrate/eMigrate-advantage/index.vue";
import EMigrateApplication from "@/components/eMigrate/eMigrate-application/index.vue";

export default {
	name: "index",
	components: {EMigrateApplication, EMigrateAdvantage, EMigrateStructure, EMigrateChallenge}
}
</script>

<style scoped>

</style>