<template>
	<div class="function">
		<div class="function_wrap">
			<p class="function_title">功能</p>
			<div class="function_content">
				<el-carousel class="function_carousel" indicator-position="outside" arrow="always">
					<el-carousel-item class="function_carousel_item1">
						<div class="thinBody">
							<div class="thinBody_left">
								<p class="thinBody_p1">存储“瘦身”，<br/>低成本实现高效存储访问</p>
								<div class="thinBody_line"></div>
								<p class="thinBody_p2">低存储量融合缓存，享受高性能数据读写体验</p>
								<p class="thinBody_p3">利用融合缓存技术，将数据进行本地和云端的分层存储，在大大降低本地存储空间的同时，实现数据的快速检索定位，拥有云端同本地盘一样的读写体验，不受网络的影响。</p>
							</div>
							<div class="thinBody_right">
								<img class="thinBody_img" :src="require('@/assets/eBackup/mainframe-computer-server.png')"/>
							</div>
						</div>
						<div class="function_carousel_line"></div>
						<div class="cache">
							<p class="cache_title">智能缓存策略，自动实现数据本地和云端的分层</p>
							<div class="cache_content">
								<div class="cache_space">
									<div class="cache_space_img">
										<img class="space_img" :src="spaceImg"/>
									</div>
									<div class="cache_space_text">
										<p class="cache_space_p1">空间策略</p>
										<p class="cache_space_p2">正常阈值范围内数据不删除，超出正常阈值时发热度策略，超出警戒阈值时数据强制搬迁<br/>上云。</p>
									</div>
								</div>
								<div class="cache_hot">
									<div class="cache_hot_img">
										<img class="hot_img" :src="hotImg"/>
									</div>
									<div class="cache_hot_text">
										<p class="cache_hot_p1">热度策略</p>
										<p class="cache_hot_p2">警戒阈值内的数据根据数据块的访问频度进行迁移，迁移完成后从缓存空间中删除，<br/>保证缓存容量。</p>
									</div>
								</div>
								<div class="cache_time">
									<div class="cache_time_img">
										<img class="time_img" :src="timeImg"/>
									</div>
									<div class="cache_time_text">
										<p class="cache_time_p1">时间策略</p>
										<p class="cache_time_p2">支持设置数据多久无访问后自动向云端转存，支持秒级、分钟级的时间策略设置。</p>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item class="function_carousel_item1">
						<div class="thinBody">
							<div class="thinBody_left">
								<p class="fuse_title">应用无需改造，<br/>云与本地无缝融合切换</p>
							</div>
							<div class="thinBody_right">
								<img class="thinBody_img" :src="serverImg"/>
							</div>
						</div>
						<div class="function_carousel_line"></div>
						<div class="cache">
							<div class="fuse_content">
								<div class="out_of_box">
									<div class="out_of_box_img">
										<img class="out_of_box_space_img" :src="spaceImg"/>
									</div>
									<div class="out_of_box_text">
										<p class="out_of_box_p">无需修改架构和应用代码，开箱即用。</p>
									</div>
								</div>
								<div class="use_old_equipment">
									<div class="use_old_equipment_img">
										<img class="use_old_equipment_hot_img" :src="hotImg"/>
									</div>
									<div class="use_old_equipment_text">
										<p class="use_old_equipment_p">无需投入巨资建设异地物理备份机房，利用旧原有设备。</p>
									</div>
								</div>
								<div class="compatible_agreement">
									<div class="compatible_agreement_img">
										<img class="compatible_agreement_time_img" :src="timeImg"/>
									</div>
									<div class="compatible_agreement_text">
										<p class="compatible_agreement_p">兼容本地存储服务协议，无缝对接云端，轻松实现能力升级，享受本地高性能读写体验和云端海量存储空间。</p>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Function",
	data(){
		return {
			spaceImg: require('@/assets/eBackup/project_space.png'),
			hotImg: require('@/assets/eBackup/hot_img.png'),
			timeImg: require('@/assets/eBackup/time_img.png'),
			serverImg: require('@/assets/eBackup/server.jpg'),
		}
	},
	methods: {},
	created() {

	},
	computed: {},
	watch: {},

}
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 150px;
	margin: 0;
}
.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
}
::v-deep .el-carousel__arrow--left {
	left: 0px;
}
::v-deep .el-carousel__arrow--right {
	right: 0px;
}
::v-deep .el-carousel__container{
	height: 720px;
}
.indicatorClass {
	background: rgba(13, 126, 191, 1);
}
.function {
	width: 1920px;
	height: 995px;
	background: rgba(245, 248, 255, 1);
	/* 子元素水平居中 */
	display: flex;
	justify-content: center; /* 水平居中 */
	.function_wrap {
		width: 1500px;
		.function_title {
			width: 1500px;
			height: 47px;
			padding-top: 69px;
			/** 文本1 */
			font-size: 32px;
			font-weight: 700;
			letter-spacing: 0;
			line-height: 46.34px;
			color: rgba(51, 51, 51, 1);
			text-align: center;
			vertical-align: top;
		}
		.function_content {
			width: 1500px;
			height: 816px;
			margin-top: 32px;
			.function_carousel {
				width: 1500px;
				.function_carousel_item1{
					background: rgba(255, 255, 255, 1);
					width: 1396px;
					height: 720px;
					margin-left: 52px;
					.thinBody{
						width: 1319px;
						height: 325px;
						margin: auto;
						margin-top: 60px;
						.thinBody_left{
							float: left;
							width: 574px;
							height: 325px;
							.thinBody_p1{
								margin-top: 12px;
								width: 352px;
								height: 84px;
								opacity: 1;
								/** 文本1 */
								font-size: 32px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 41.89px;
								color: rgba(13, 126, 191, 1);
								text-align: left;
								vertical-align: top;
							}
							.thinBody_line{
								margin-top: 37px;
								width: 35px;
								height: 6px;
								opacity: 1;
								border-radius: 2.99px;
								background: rgba(13, 126, 191, 1);
							}
							.thinBody_p2{
								margin-top: 34px;
								width: 440px;
								height: 32px;
								opacity: 1;
								/** 文本1 */
								font-size: 22px;
								font-weight: 400;
								letter-spacing: 0;
								line-height: 31.78px;
								color: rgba(51, 51, 51, 1);
								text-align: left;
								vertical-align: top;
							}
							.thinBody_p3{
								margin-top: 20px;
								width: 575px;
								height: 101px;
								opacity: 1;
								/** 文本1 */
								font-size: 16px;
								font-weight: 300;
								letter-spacing: 0;
								line-height: 23.11px;
								color: rgba(102, 102, 102, 1);
								text-align: left;
								vertical-align: top;
							}
							.fuse_title {
								margin-top: 15%;
								width: 352px;
								height: 84px;
								opacity: 1;
								color: rgba(13, 126, 191, 1);
								/** 文本1 */
								font-size: 32px;
								letter-spacing: 0;
								line-height: 42px;
								text-align: left;
								vertical-align: top;
								font-weight: 550
							}
						}
						.thinBody_right{
							float: left;
							width: 710px;
							height: 325px;
							margin-left: 35px;
							.thinBody_img{
								width: 710px;
								height: 325px;
							}
						}
					}
					.function_carousel_line{
						margin: 40px auto auto;
						width: 1318px;
						height: 1px;
						opacity: 1;
						background: rgba(204, 204, 204, 1);
					}
					.cache{
						width: 1317px;
						height: 187px;
						margin: 46px auto auto;
						.cache_title{
							width: 462px;
							height: 32px;
							opacity: 1;
							/** 文本1 */
							font-size: 22px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 31.78px;
							color: rgba(51, 51, 51, 1);
							text-align: left;
							vertical-align: top;
						}
						.cache_content{
							margin-top: 35px;
							width: 1317px;
							height: 120px;
							.cache_space{
								float: left;
								margin-right: 51px;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.cache_space_img{
									float: left;
									margin-top: 24px;
									margin-left: 22px;
									width: 32px;
									height: 33px;
									.space_img{
										width: 32px;
										height: 33px;
									}
								}
								.cache_space_text{
									float: left;
									margin-left: 22px;
									margin-top: 15px;
									width: 314px;
									height: 112px;
									.cache_space_p1{
										width: 88px;
										height: 32px;
										opacity: 1;
										/** 文本1 */
										font-size: 22px;
										font-weight: 400;
										letter-spacing: 0;
										line-height: 31.78px;
										color: rgba(51, 51, 51, 1);
										text-align: left;
										vertical-align: top;
									}
									.cache_space_p2{
										margin-top: 10px;
										width: 314px;
										height: 70px;
										opacity: 1;
										/** 文本1 */
										font-size: 14px;
										font-weight: 300;
										letter-spacing: 0;
										line-height: 20.22px;
										color: rgba(102, 102, 102, 1);
										text-align: left;
										vertical-align: top;
									}
								}
							}
							.cache_hot{
								float: left;
								margin-right: 51px;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.cache_hot_img{
									float: left;
									margin-top: 20px;
									margin-left: 21px;
									width: 32px;
									height: 40px;
									.hot_img{
										width: 32px;
										height: 40px;
									}
								}
								.cache_hot_text{
									float: left;
									width: 313px;
									height: 111px;
									margin-top: 15px;
									margin-left: 23px;
									.cache_hot_p1{
										width: 88px;
										height: 32px;
										opacity: 1;
										/** 文本1 */
										font-size: 22px;
										font-weight: 400;
										letter-spacing: 0;
										line-height: 31.78px;
										color: rgba(51, 51, 51, 1);
										text-align: left;
										vertical-align: top;
									}
									.cache_hot_p2{
										margin-top: 10px;
										width: 312px;
										height: 70px;
										opacity: 1;
										/** 文本1 */
										font-size: 13.96px;
										font-weight: 300;
										letter-spacing: 0;
										line-height: 20.22px;
										color: rgba(102, 102, 102, 1);
										text-align: left;
										vertical-align: top;
									}
								}
							}
							.cache_time{
								float: left;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.cache_time_img{
									float: left;
									margin-top: 24px;
									margin-left: 22px;
									width: 32px;
									height: 33px;
									.time_img{
										width: 32px;
										height: 33px;
									}
								}
								.cache_time_text{
									float: left;
									margin-top: 15px;
									margin-left: 22px;
									width: 313px;
									height: 111px;
									.cache_time_p1{
										width: 88px;
										height: 32px;
										opacity: 1;
										/** 文本1 */
										font-size: 22px;
										font-weight: 400;
										letter-spacing: 0;
										line-height: 31.78px;
										color: rgba(51, 51, 51, 1);
										text-align: left;
										vertical-align: top;
									}
									.cache_time_p2{
										margin-top: 10px;
										width: 312px;
										height: 70px;
										opacity: 1;
										/** 文本1 */
										font-size: 14px;
										font-weight: 300;
										letter-spacing: 0;
										line-height: 20.22px;
										color: rgba(102, 102, 102, 1);
										text-align: left;
										vertical-align: top;
									}
								}
							}
						}
						.fuse_content{
							margin-top: 70px;
							width: 1317px;
							height: 120px;
							.out_of_box{
								float: left;
								margin-right: 51px;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.out_of_box_img{
									float: left;
									margin-top: 24px;
									margin-left: 22px;
									width: 32px;
									height: 33px;
									.out_of_box_space_img{
										width: 32px;
										height: 33px;
									}
								}
								.out_of_box_text{
									float: left;
									margin-left: 22px;
									width: 329px;
									.out_of_box_p{
										margin-top: 24px;
										font-size: 18px;
										color: gray;
										text-align: left;
									}
								}
							}
							.use_old_equipment{
								float: left;
								margin-right: 51px;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.use_old_equipment_img{
									float: left;
									margin-top: 20px;
									margin-left: 21px;
									width: 32px;
									height: 40px;
									.use_old_equipment_hot_img{
										width: 32px;
										height: 40px;
									}
								}
								.use_old_equipment_text{
									float: left;
									margin-left: 22px;
									width: 330px;
									.use_old_equipment_p{
										margin-top: 24px;
										font-size: 18px;
										color: gray;
										text-align: left;
									}
								}
							}
							.compatible_agreement{
								float: left;
								width: 405px;
								height: 120px;
								background: rgba(245, 248, 255, 1);
								.compatible_agreement_img{
									float: left;
									margin-top: 24px;
									margin-left: 22px;
									width: 32px;
									height: 33px;
									.compatible_agreement_time_img{
										width: 32px;
										height: 33px;
									}
								}
								.compatible_agreement_text{
									float: left;
									margin-left: 22px;
									width: 329px;
									.compatible_agreement_p{
										margin-top: 24px;
										font-size: 18px;
										color: gray;
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
			.function_content_top_center {
				margin-left: 40px;
				width: 1396px;
				height: 720px;
				background: rgba(255, 255, 255, 1);
				.center_top {
					width: 1400px;
					height: 366px;
					.center_top_wrap {
						margin-top: 60px;
						margin-left: 38px;
						width: 1323px;
						height: 326px;
						.center_top_wrap_right {
							float: left;
							width: 712px;
							height: 326px;
							background-color: #e0eaf7;
							margin-left: 35px;
						}
					}
				}
			}
		}
	}
}
</style>