<template>
	<div class="structure">
		<div class="background">
			<img class="background_img" :src="background">
		</div>
		<div class="structure_content">
			<div class="content_wrap">
				<div class="top">
					<p class="top1">简介/架构图</p>
					<p class="top2">
						专为企业提供数据保护和恢复功能的解决方案。它通过备份关键数据并存储在安全的位置，以防止数据丢失和泄露。
					</p>
				</div>
				<div class="botton">
					<img class="bottom-img" :src="framework"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Structure",
	data() {
		return {
			framework: require('@/assets/structure/framework.png'),
			background: require('@/assets/structure/background.png'),
		}
	},
	methods: {},
	created() {

	},
	computed: {},
	watch: {},
}
</script>

<style lang="scss" scoped>
.structure {
	position: relative;
	height: 894px;
	width: 1920px;
	.background {
		position: absolute;
		width: 100%;
		height: 894px;
		.background_img{
			width: 100%;
			height: 894px;
		}
	}
	.structure_content {
		position: absolute;
		width: 100%;
		height: 894px;
		/* 子元素居中 */
		display: flex;
		justify-content: center; /* 子元素水平居中 */
		.content_wrap{
			width: 1501px;
			height: 894px;

		}
	}
}

.top {
	height: 218px;
}

.top1 {
	width: 100%;
	height: 47px;
	padding-top: 71px;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(34, 34, 34, 1);
	text-align: center;
	vertical-align: top;
}

.top2 {
	width: 100%;
	height: 77.72px;
	margin-top: 28px;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}

.botton {
  width: 1501px;
	height: 519px;
  .bottom-img {
    width: 1501px;
    height: 519px;
  }
}

</style>