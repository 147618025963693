<template>
	<div class="eManage_structure">
		<div class="eManage_structure_background">
			<img class="eManage_structure_background_img" :src="structureBackground"/>
		</div>
		<div class="eManage_structure_content">
			<div class="eManage_structure_wrap">
				<p class="eManage_structure_wrap_p1">简介/架构图</p>
				<p class="eManage_structure_wrap_p2">云计算技术引领桌面进入“后PC时代”，将桌面操作系统、应用软件和数据全部迁移到云数据中心，根本上解决传统PC桌面面临的困境</p>
				<img class="eManage_structure_wrap_img" :src="structure"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EManageStructure",
	data() {
		return {
			structureBackground: require('@/assets/eManage/eManage-structure/beijing.png'),
			structure: require('@/assets/eManage/eManage-structure/jiagoutu.png'),
		}
	},
	methods: {},
	created() {},
	computed: {},
	watch: {},
}
</script>

<style lang="scss" scoped>
.eManage_structure{
	position: relative;
	width: 1920px;
	height: 990px;
	.eManage_structure_background{
		position: absolute;
		width: 100%;
		height: 990px;
		.eManage_structure_background_img{
			width: 100%;
			height: 990px;
		}
	}
	.eManage_structure_content{
		position: absolute;
		width: 100%;
		height: 990px;
		display: flex;
		justify-content: center;
		.eManage_structure_wrap{
			width: 1186px;
			height: 100%;
			.eManage_structure_wrap_p1{
				margin-top: 71px;
				width: 100%;
				height: 47px;
				opacity: 1;
				/** 文本1 */
				font-size: 31.92px;
				font-weight: 700;
				letter-spacing: 0;
				line-height: 46.22px;
				color: rgba(34, 34, 34, 1);
				text-align: center;
				vertical-align: top;
			}
			.eManage_structure_wrap_p2{
				margin-top: 30px;
				width: 100%;
				height: 77.52px;
				opacity: 1;
				/** 文本1 */
				font-size: 15.96px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: 23.11px;
				color: rgba(102, 102, 102, 1);
				text-align: center;
				vertical-align: top;
			}
			.eManage_structure_wrap_img{
				margin-top: 2px;
				width: 1186px;
				height: 680px;
			}
		}
	}
}
</style>