<template>
	<div class="challenge">
		<p class="challengeP">痛点/挑战</p>
		<div class="challenge_content">
			<div class="challenge_content_div1">
				<div class="challenge_content_div1_wrap">
					<div class="content_div1_wrap_left">
						<img class="content_div1_wrap_left_img" :src="cost"/>
					</div>
					<div class="content_div1_wrap_right">
						<p class="content_div1_wrap_right_p1">成本投入高</p>
						<p class="content_div1_wrap_right_p2">传统灾备投入大、成本高，前期投入耗费大量人力物力</p>
					</div>
				</div>
			</div>
			<div class="challenge_content_div2">
				<div class="challenge_content_div2_wrap">
					<div class="content_div2_wrap_left">
						<img class="content_div2_wrap_left_img" :src="devOps"/>
					</div>
					<div class="content_div2_wrap_right">
						<p class="content_div2_wrap_right_p1">运维管控难</p>
						<p class="content_div2_wrap_right_p2">统筹硬件、软件、设备环境维护难度大，无法得到及时快速响应</p>
					</div>
				</div>
			</div>
			<div class="challenge_content_div3">
				<div class="challenge_content_div3_wrap">
					<div class="content_div3_wrap_left">
						<img class="content_div3_wrap_left_img" :src="fault"/>
					</div>
					<div class="content_div3_wrap_right">
						<p class="content_div3_wrap_right_p1">容错风险高</p>
						<p class="content_div3_wrap_right_p2">意外发生备份数据恢复重建导致业务停机，耗时长</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Challenge",
	data(){
		return {
      cost: require('@/assets/challenge/cost.png'),
      devOps: require('@/assets/challenge/devOps.png'),
      fault: require('@/assets/challenge/fault.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style lang="scss" scoped>
.challenge{
	width: 1920px;
	height: 500px;
	.challengeP{
		width: 100%;
		height: 47px;
		padding-top: 9%;
		/** 文本1 */
		font-size: 32px;
		font-weight: 700;
		/*字符之间的间距设置为0像素，意味着字符之间没有额外的空间*/
		letter-spacing: 0;
		line-height: 46.34px;
		color: rgba(34, 34, 34, 1);
		/*这将文本水平居中对齐*/
		text-align: center;
		/*文本在垂直方向上的对齐方式为顶部对齐*/
		vertical-align:top;
	}
	.challenge_content{
		width: 100%;
		height: 281px;
		padding-top: 35px;
		/* 子元素水平居中 */
		display: flex;
		justify-content: center;
	}
	.challenge_content_div1{
		background-color: rgba(245, 248, 255, 1);
		width: 440px;
		height: 200px;
		float: left;
		margin-right: 40px;
		.challenge_content_div1_wrap{
			width: 353px;
			height: 99px;
			margin-left: 24px;
			margin-top: 61px;
			.content_div1_wrap_left{
				float: left;
				.content_div1_wrap_left_img{
					width: 60px;
					height: 70px;
				}
			}
			.content_div1_wrap_right{
				float: left;
				width: 256px;
				height: 99px;
				padding-left: 36px;
				.content_div1_wrap_right_p1{
					width: 120px;
					height: 35px;
					margin-top: -8px;
					/** 文本1 */
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 0;
					line-height: 34.75px;
					color: rgba(51, 51, 51, 1);
					text-align: left;
					vertical-align: top;
				}
				.content_div1_wrap_right_p2{
					width: 257px;
					height: 61px;
					margin-top: 11px;
					/** 文本1 */
					font-size: 16px;
					font-weight: 300;
					letter-spacing: 0;
					line-height: 23.17px;
					color: rgba(102, 102, 102, 1);
					text-align: left;
					vertical-align: top;
				}
			}
		}
	}
	.challenge_content_div2{
		background-color: rgba(245, 248, 255, 1);
		width: 440px;
		height: 200px;
		float: left;
		margin-right: 40px;
		.challenge_content_div2_wrap{
			width: 359px;
			height: 99px;
			margin-left: 28px;
			margin-top: 61px;
			.content_div2_wrap_left{
				float: left;
				.content_div2_wrap_left_img{
					width: 73px;
					height: 73px;
				}
			}
			.content_div2_wrap_right{
				float: left;
				width: 257px;
				height: 99px;
				margin-left: 29px;
				.content_div2_wrap_right_p1{
					width: 120px;
					height: 35px;
					margin-top: -8px;
					/** 文本1 */
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 0;
					line-height: 34.75px;
					color: rgba(51, 51, 51, 1);
					text-align: left;
					vertical-align: top;
				}
				.content_div2_wrap_right_p2{
					width: 256.5px;
					height: 61px;
					margin-top: 11px;
					/** 文本1 */
					font-size: 16px;
					font-weight: 300;
					letter-spacing: 0;
					line-height: 23.17px;
					color: rgba(102, 102, 102, 1);
					text-align: left;
					vertical-align: top;
				}
			}
		}
	}
	.challenge_content_div3{
		background-color: rgba(245, 248, 255, 1);
		width: 440px;
		height: 200px;
		float: left;
		.challenge_content_div3_wrap{
			width: 354px;
			height: 103px;
			margin-left: 33px;
			margin-top: 57px;
			.content_div3_wrap_left{
				float: left;
				.content_div3_wrap_left_img{
					width: 65px;
					height: 79px;
				}
			}
			.content_div3_wrap_right{
				float: left;
				width: 257px;
				height: 103px;
				margin-left: 32px;
				.content_div3_wrap_right_p1{
					width: 120px;
					height: 35px;
					margin-top: -8px;
					/** 文本1 */
					font-size: 24px;
					font-weight: 400;
					letter-spacing: 0;
					line-height: 34.75px;
					color: rgba(51, 51, 51, 1);
					text-align: left;
					vertical-align: top;
				}
				.content_div3_wrap_right_p2{
					width: 256.5px;
					height: 61px;
					margin-top: 15px;
					/** 文本1 */
					font-size: 16px;
					font-weight: 300;
					letter-spacing: 0;
					line-height: 23.17px;
					color: rgba(102, 102, 102, 1);
					text-align: left;
					vertical-align: top;
				}
			}
		}
	}
}
</style>