<template>
	<div class="eManage_advantage">
		<div class="eManage_advantage_wrap">
			<p class="eManage_advantage_wrap_p">优势</p>
			<div class="eManage_advantage_wrap_div1">
				<div class="eManage_advantage_wrap_div1_left">
					<div class="eManage_advantage_div1_left_content">
						<p class="eManage_advantage_div1_left_content_p1">先进的分布式高可用融合架构</p>
						<p class="eManage_advantage_div1_left_content_p2">从企业IT投资成本考量，云玓云桌面系统LinkedStor Yundy人工智能桌面在同一套单元设备中不仅仅具备计算、网络、存储和服务器虚拟化等资源和技术，而且还包括缓存加速、重复数据删除、在线数据压缩、备份软件、快照技术等元素，而多节点可以通过网络聚合起来，实现模块化的无缝横向扩展</p>
					</div>
					<img class="eManage_advantage_div1_left_img" :src="advantage1"/>
				</div>
				<div class="eManage_advantage_wrap_div1_right">
					<div class="eManage_advantage_div1_right_content">
						<p class="eManage_advantage_div1_right_content_p1">行业领先的硬件利用率</p>
						<p class="eManage_advantage_div1_right_content_p2">在不影响用户体验的基础下，相同服务器硬件指标参数领步人工智能桌面比常规设置能多部署20%以上云桌面</p>
					</div>
					<img class="eManage_advantage_div1_right_img" :src="advantage2"/>
				</div>
			</div>
			<div class="eManage_advantage_wrap_div2">
				<div class="eManage_advantage_wrap_div2_left">
					<div class="eManage_advantage_div2_left_content">
						<p class="eManage_advantage_div2_left_content_p1">简洁便捷的操作使用</p>
						<p class="eManage_advantage_div2_left_content_p2">异构平台管理界面及相关设置均以满足用户使用习惯的角度来设计，用户培训成本低，上手快驱动自动智能转换适配，无需人为介入操作</p>
					</div>
					<img class="eManage_advantage_div2_left_img" :src="advantage3"/>
				</div>
				<div class="eManage_advantage_wrap_div2_right">
					<div class="eManage_advantage_div2_right_content">
						<p class="eManage_advantage_div2_right_content_p1">创新的桌面访问方式和体验</p>
						<p class="eManage_advantage_div2_right_content_p2">从企业安全性考量，提供多种登录，访问，数据交换的认证的方式，包括软件平台认证，插卡认证，生物识别认证及其的组合方式认证</p>
					</div>
					<img class="eManage_advantage_div2_right_img" :src="advantage4"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import advantage from "@/components/eBackup/advantage/index.vue";

export default {
	name: "EManageAdvantage",
	data() {
		return {
			advantage1: require('@/assets/eManage/eManage_advantage/4.png'),
			advantage2: require('@/assets/eManage/eManage_advantage/3.png'),
			advantage3: require('@/assets/eManage/eManage_advantage/2.png'),
			advantage4: require('@/assets/eManage/eManage_advantage/1.png'),
		}
	},
	methods: {},
	created() {},
	computed: {
		advantage() {
			return advantage
		}
	},
	watch: {},
}
</script>

<style lang="scss" scoped>
.eManage_advantage{
	width: 1920px;
	height: 665px;
	display: flex;
	justify-content: center;
	.eManage_advantage_wrap{
		width: 1396px;
		height: 100%;
		.eManage_advantage_wrap_p{
			margin-top: 70px;
			width: 100%;
			height: 47px;
			opacity: 1;
			/** 文本1 */
			font-size: 31.92px;
			font-weight: 700;
			letter-spacing: 0;
			line-height: 46.22px;
			color: rgba(34, 34, 34, 1);
			text-align: center;
			vertical-align: top;
		}
		.eManage_advantage_wrap_div1{
			margin-top: 33px;
			width: 100%;
			height: 233px;
			.eManage_advantage_wrap_div1_left{
				float: left;
				width: 678px;
				height: 233px;
				background: rgba(245, 248, 255, 1);
				.eManage_advantage_div1_left_content{
					float: left;
					margin-top: 33px;
					margin-left: 39px;
					width: 450px;
					height: 157px;
					.eManage_advantage_div1_left_content_p1{
						width: 100%;
						height: 35px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.eManage_advantage_div1_left_content_p2{
						margin-top: 11px;
						width: 450.33px;
						height: 155px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.eManage_advantage_div1_left_img{
					margin-top: 128px;
					margin-left: 74px;
					width: 68px;
					height: 66px;
				}
			}
			.eManage_advantage_wrap_div1_right{
				float: left;
				margin-left: 40px;
				width: 678px;
				height: 233px;
				background: linear-gradient(270deg, rgba(112, 168, 64, 1) 0%, rgba(13, 126, 191, 1) 100%);
				.eManage_advantage_div1_right_content{
					float: left;
					margin-top: 31px;
					margin-left: 38px;
					width: 450px;
					height: 116px;
					.eManage_advantage_div1_right_content_p1{
						width: 100%;
						height: 35px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.eManage_advantage_div1_right_content_p2{
						margin-top: 11px;
						width: 450px;
						height: 70.49px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.eManage_advantage_div1_right_img{
					margin-top: 139px;
					margin-left: 74px;
					width: 78px;
					height: 56px;
				}
			}
		}
		.eManage_advantage_wrap_div2{
			margin-top: 40px;
			width: 100%;
			height: 162px;
			.eManage_advantage_wrap_div2_left{
				float: left;
				width: 678px;
				height: 162px;
				background: rgba(245, 248, 255, 1);
				.eManage_advantage_div2_left_content{
					float: left;
					margin-top: 32px;
					margin-left: 37px;
					width: 450px;
					height: 130px;
					.eManage_advantage_div2_left_content_p1{
						width: 100%;
						height: 35px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.eManage_advantage_div2_left_content_p2{
						margin-top: 11px;
						width: 450.33px;
						height: 84px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.eManage_advantage_div2_left_img{
					float: left;
					margin-top: 51px;
					margin-left: 85px;
					width: 67px;
					height: 70px;
				}
			}
			.eManage_advantage_wrap_div2_right{
				float: left;
				margin-left: 40px;
				width: 678px;
				height: 162px;
				background: rgba(245, 248, 255, 1);
				.eManage_advantage_div2_right_content{
					float: left;
					margin-top: 32px;
					margin-left: 39px;
					width: 450px;
					height: 130px;
					.eManage_advantage_div2_right_content_p1{
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.eManage_advantage_div2_right_content_p2{
						margin-top: 11px;
						width: 450px;
						height: 84px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.eManage_advantage_div2_right_img{
					float: left;
					margin-top: 52px;
					margin-left: 85px;
					width: 69px;
					height: 69px;
				}
			}
		}
	}
}
</style>