<template>
	<div class="eMigrate_structure">
		<div class="eMigrate_structure_background">
			<img class="eMigrate_structure_background_img" :src="background"/>
		</div>
		<div class="eMigrate_structure_wrap">
			<p class="eMigrate_structure_wrap_top1">简介/架构图</p>
			<p class="eMigrate_structure_wrap_top2">专为用户打造智能在线迁移的解决方案，帮助用户轻松快迁移</p>
			<div class="eMigrate_structure_botton">
				<img class="eMigrate_structure_botton_img" :src="framework"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMigrateStructure",
	data(){
		return {
			background: require('@/assets/eMigrate/eMigrate-structure/beijing.png'),
			framework: require('@/assets/eMigrate/eMigrate-structure/structureImg.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},
}
</script>

<style lang="scss" scoped>
.eMigrate_structure{
	width: 1920px;
	height: 918px;
	position: relative;
	background-color: #aaaaaa;
	.eMigrate_structure_background{
		width: 100%;
		height: 918px;
		position: absolute;
		.eMigrate_structure_background_img{
			width: 100%;
			height: 918px;
		}
	}
	.eMigrate_structure_wrap{
		width: 100%;
		height: 918px;
		//height: 223px;
		position: absolute;
		.eMigrate_structure_wrap_top1{
			width: 100%;
			height: 47px;
			padding-top: 71px;
			/** 文本1 */
			font-size: 32px;
			font-weight: 700;
			letter-spacing: 0;
			line-height: 46.34px;
			color: rgba(34, 34, 34, 1);
			text-align: center;
			vertical-align: top;
		}
		.eMigrate_structure_wrap_top2{
			margin-top: 38px;
			width: 100%;
			height: 55px;
			opacity: 1;
			/** 文本1 */
			font-size: 15.96px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 23.11px;
			color: rgba(102, 102, 102, 1);
			text-align: center;
			vertical-align: top;
		}
		.eMigrate_structure_botton{
			position: absolute;
			margin-bottom: 6px;
			width: 100%;
			height: 674px;
			.eMigrate_structure_botton_img{
				width: 1340px;
				height: 674px;
			}
		}
	}
}

</style>