<template>
	<div class="eMove_scenarios">
		<p class="eMove_scenarios_p">应用场景</p>
		<div class="eMove_scenarios_wrap">
			<div class="eMove_scenarios_content">
				<div v-on:mouseenter="tabOnmouseover('div1')" class="eMove_scenarios_content_div1" :class="{active: active == 'div1'}">
					<div class="eMove_scenarios_content_div1_background">
						<img class="eMove_scenarios_content_div1_background_img" :src="first"/>
					</div>
					<div class="eMove_scenarios_content_div1_wrap">
						<p class="eMove_scenarios_div1_wrap_p1">企业IT系统升级</p>
						<div class="eMove_scenarios_div1_wrap_div"></div>
						<p v-if="active == 'div1'" class="eMove_scenarios_div1_wrap_p2">当企业需要升级其现有的IT系统时，e键搬工具可以帮助将现有的应用程序和数据迁移到云端，从而实现系统的升级和迁移。</p>
            <p v-else class="eMove_scenarios_div1_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
				</div>
				<div v-on:mouseenter="tabOnmouseover('div2')" class="eMove_scenarios_content_div2" :class="{active: active == 'div2'}">
					<div class="eMove_scenarios_content_div2_background">
						<img class="eMove_scenarios_content_div2_background_img" :src="second"/>
					</div>
					<div class="eMove_scenarios_content_div2_wrap">
						<p class="eMove_scenarios_div2_wrap_p1">数据中心迁移</p>
						<div class="eMove_scenarios_div2_wrap_div"></div>
            <p v-if="active == 'div2'" class="eMove_scenarios_div2_wrap_p2">当企业需要将其数据中心迁移到云端或者不同的数据中心时，e键搬工具可以帮助将应用程序和数据无缝地迁移到新的环境中，减少迁移过程中的停机时间和数据丢失风险。</p>
            <p v-else class="eMove_scenarios_div2_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
				</div>
				<div v-on:mouseenter="tabOnmouseover('div3')" class="eMove_scenarios_content_div3" :class="{active: active == 'div3'}">
					<div class="eMove_scenarios_content_div3_background">
						<img class="eMove_scenarios_content_div3_background_img" :src="thired"/>
					</div>
					<div class="eMove_scenarios_content_div3_wrap">
						<p class="eMove_scenarios_div3_wrap_p1">应用程序测试和开发环境部署</p>
						<div class="eMove_scenarios_div3_wrap_div"></div>
            <p v-if="active == 'div3'" class="eMove_scenarios_div3_wrap_p2">e键搬工具可以帮助开发人员和测试人员快速部署和迁移应用程序到云端环境，提高开发和测试的效率，同时降低环境配置和管理的复杂性。</p>
            <p v-else class="eMove_scenarios_div3_wrap_p2">鼠标聚焦，查看介绍</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMoveScenarios",
	data() {
		return {
			first: require('@/assets/eMove/eMove-scenarios/3.png'),
			second: require('@/assets/eMove/eMove-scenarios/2.png'),
			thired: require('@/assets/eMove/eMove-scenarios/1.png'),
      active: 'div1',
		}
	},
	methods: {
    tabOnmouseover(name) {
      this.active = name
    }
  },
	created() {

	},
	computed: {},
	watch: {},

}
</script>

<style lang="scss" scoped>
.active {
  transition: width 1.3s;
  width: 695px!important;
}
.eMove_scenarios{
	width: 1920px;
	height: 731px;
	opacity: 1;
	background: rgba(245, 248, 255, 1);
	.eMove_scenarios_p{
		padding-top: 69px;
		width: 100%;
		height: 47px;
		opacity: 1;
		/** 文本1 */
		font-size: 31.92px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 46.22px;
		color: rgba(34, 34, 34, 1);
		text-align: center;
		vertical-align: top;
	}
	.eMove_scenarios_wrap{
		width: 100%;
		height: 501px;
		display: flex;
		justify-content: center;
		.eMove_scenarios_content{
			margin-top: 33px;
			width: 1920px;
      margin-left: 250px;
			height: 501px;
			.eMove_scenarios_content_div1{
				float: left;
				margin-right: 1px;
				position: relative;
				width: 349px;
				height: 501px;
				.eMove_scenarios_content_div1_background{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_content_div1_background_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_scenarios_content_div1_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_div1_wrap_p1{
						margin-top: 390px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_scenarios_div1_wrap_div{
						margin-top: 6px;
						margin-left: 42px;
						width: 45.88px;
						height: 5.98px;
						opacity: 1;
						border-radius: 2.99px;
						background: rgba(255, 255, 255, 1);
					}
					.eMove_scenarios_div1_wrap_p2{
						margin-top: 17px;
						margin-left: 40px;
						width: 90%;
						height: 20.95px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
				}
			}
			.eMove_scenarios_content_div2{
				float: left;
				margin-right: 1px;
				position: relative;
				width: 349px;
				height: 501px;
				.eMove_scenarios_content_div2_background{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_content_div2_background_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_scenarios_content_div2_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_div2_wrap_p1{
						margin-top: 390px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_scenarios_div2_wrap_div{
						margin-top: 7px;
						margin-left: 42px;
						width: 45.88px;
						height: 5.98px;
						opacity: 1;
						border-radius: 2.99px;
						background: rgba(255, 255, 255, 1);
					}
          .eMove_scenarios_div2_wrap_p2{
            margin-top: 17px;
            margin-left: 40px;
            width: 90%;
            height: 20.95px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
				}
			}
			.eMove_scenarios_content_div3{
				float: left;
				position: relative;
				width: 349px;
				height: 501px;
				.eMove_scenarios_content_div3_background{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_content_div3_background_img{
						width: 100%;
						height: 501px;
					}
				}
				.eMove_scenarios_content_div3_wrap{
					position: absolute;
					width: 100%;
					height: 501px;
					.eMove_scenarios_div3_wrap_p1{
						margin-top: 390px;
						margin-left: 41px;
						width: 100%;
						height: 23.94px;
						opacity: 1;
						/** 文本1 */
						font-size: 15.96px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 23.11px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_scenarios_div3_wrap_div{
						margin-top: 7px;
						margin-left: 42px;
						width: 45.88px;
						height: 5.98px;
						opacity: 1;
						border-radius: 2.99px;
						background: rgba(255, 255, 255, 1);
					}
          .eMove_scenarios_div3_wrap_p2{
            margin-top: 17px;
            margin-left: 40px;
            width: 90%;
            height: 20.95px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
				}
			}
		}
	}
}
</style>