<template>
	<div class="case">
		<div class="case_wrap">
			<div class="case_wrap_top">
				<div class="case_wrap_top_left">
					<div class="case_wrap_top_left_wrap">
						<p class="case_wrap_top_left_wrap_p1">某市妇女儿童医疗中心</p>
						<div></div>
						<p class="case_wrap_top_left_wrap_p2">国家儿童区域医疗中心<br/>国家区域医疗中心建设输出单位</p>
						<p class="case_wrap_top_left_wrap_p3">由市儿童医院、市妇婴医院、市妇幼保健院、市人口和计划生育技术服务指导所整合而成</p>
					</div>
				</div>
				<div class="case_wrap_top_right"><img class="glass-img" :src="glass"/></div>
			</div>
			<p class="case_wrap_center">客户需求</p>
			<div class="case_wrap_botton">
				<div class="case_wrap_botton_div1">
					<div class="case_wrap_botton_div1_wrap">
						<div><img class="icon" :src="erji"/></div>
					</div>
					<div class="case_wrap_botton_p_wrap">
						<p class="case_wrap_botton_p1">自动化运维、审计及安全管控难，无法实现数据统一化备份，存在数据中心整体故障影响前端业务的风险</p>
					</div>
				</div>
				<div class="case_wrap_botton_div2">
					<div class="case_wrap_botton_div2_wrap">
						<div><img class="icon" :src="anquan"/></div>
					</div>
					<div class="case_wrap_botton_p_wrap">
						<p class="case_wrap_botton_p2">数据安全性差，通用操作系统无法提供如数据快照等高级数据保护方式</p>
					</div>
				</div>
				<div class="case_wrap_botton_div3">
					<div class="case_wrap_botton_div3_wrap">
						<div><img class="icon" :src="dinghou"/></div>
					</div>
					<div class="case_wrap_botton_p_wrap">
						<p class="case_wrap_botton_p3">医院环境规模大，关联复杂，难以定期专业的演练及规划预案</p>
					</div>
				</div>
				<div class="case_wrap_botton_div4">
					<div class="case_wrap_botton_div4_wrap">
						<div><img class="icon" :src="shuju"/></div>
					</div>
					<div class="case_wrap_botton_p_wrap">
						<p class="case_wrap_botton_p4">传统备份软件过分依赖主机及网络，且医院数据量庞大，备份和恢复时间消耗长</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Case",
	data(){
		return {
			erji: require('@/assets/case/erji-yunweibaobiao.png'),
			anquan: require('@/assets/case/anquan.png'),
			dinghou: require('@/assets/case/dinghuoguimo.png'),
			shuju: require('@/assets/case/shuju.png'),
			glass: require('@/assets/case/glass-buildings-seen-from-lake.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style lang="scss" scoped>
.case{
	width: 1920px;
	height: 1054px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap{
	margin-top: 200px;
	width: 1400px;
	height: 859px;
}
.case_wrap_top{
	width: 1400px;
	height: 427px;
}
.case_wrap_top_left{
	float: left;
	width: 649px;
	height: 427px;
	background: rgba(245, 248, 255, 1);
}
.case_wrap_top_left_wrap{
	margin-top: 78px;
	margin-left: 69px;
	width: 502px;
	height: 335px;
}
.case_wrap_top_left_wrap_p1{
	width: 260px;
	height: 38px;
	opacity: 1;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 37.65px;
	color: rgba(13, 126, 191, 1);
	text-align: left;
	vertical-align: top;
}
.case_wrap_top_left_wrap div{
	margin-top: 14px;
	width: 35px;
	height: 6px;
	opacity: 1;
	border-radius: 3px;
	background: rgba(13, 126, 191, 1);
}
.case_wrap_top_left_wrap_p2{
	margin-top: 14px;
	width: 252px;
	height: 56px;
	opacity: 1;
	/** 文本1 */
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 26.06px;
	color: rgba(51, 51, 51, 1);
	text-align: left;
	vertical-align: top;
}
.case_wrap_top_left_wrap_p3{
	margin-top: 28px;
	width: 502px;
	height: 178.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.case_wrap_top_right{
	float: left;
	width: 750px;
	height: 427px;
  .glass-img {
    width: 750px;
    height: 427px;
  }
}
.case_wrap_top_right img{
	width: 750px;
	height: 427px;
}
.case_wrap_center{
	margin-top: 70px;
	width: 100%;
	height: 47px;
	opacity: 1;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(51, 51, 51, 1);
	text-align: center;
	vertical-align: top;
}
.case_wrap_botton{
	margin-top: 35px;
	width: 1400px;
	height: 100%;
}
.case_wrap_botton_div1{
	float: left;
	margin-right: 40px;
	width: 320px;
	height: 280px;
	background: rgba(245, 248, 255, 1);
}
.case_wrap_botton_div1_wrap{
	margin-top: 52px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap_botton_div1_wrap div{
	width: 68px;
	height: 69px;
}
.case_wrap_botton_div2_wrap{
	margin-top: 47px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap_botton_div2_wrap div{
	width: 61px;
	height: 78px;
}
.case_wrap_botton_div3_wrap{
	margin-top: 51px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap_botton_div3_wrap div{
	width: 61px;
	height: 70px;
}
.case_wrap_botton_div4_wrap{
	margin-top: 54px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap_botton_div4_wrap div{
	width: 67px;
	height: 64px;
}
.case_wrap_botton_p_wrap{
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.case_wrap_botton_div2{
	float: left;
	margin-right: 40px;
	width: 320px;
	height: 280px;
	background: rgba(245, 248, 255, 1);
}
.case_wrap_botton_div3{
	float: left;
	margin-right: 40px;
	width: 320px;
	height: 280px;
	background: rgba(245, 248, 255, 1);
}
.case_wrap_botton_div4{
	float: left;
	width: 320px;
	height: 280px;
	background: rgba(245, 248, 255, 1);
}
.case_wrap_botton_p1{
	margin-top: 42px;
	width: 256px;
	height: 134.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}
.case_wrap_botton_p2{
	margin-top: 38px;
	width: 256px;
	height: 134.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}
.case_wrap_botton_p3{
	margin-top: 42px;
	width: 256px;
	height: 134.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}
.case_wrap_botton_p4{
	margin-top: 45px;
	width: 256px;
	height: 134.33px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}
.icon {
  width: 68px;
  height: 68px;
}
</style>